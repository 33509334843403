<template>
  <b-container fluid >
    <b-row>
      <b-col class="heading_alignment" md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="postAdd">
              {{cvbtnAddNew}}
            </b-button>
          </template>
          <template>
            <b-row class="mb-3">
              <b-col md="4">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
              <!-- <b-col md="8">
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Excel</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Csv</b-button>
                <b-button size="sm" variant="outline-secondary" class="pull-right mr-2">Coustomize</b-button>
              </b-col> -->
            </b-row>
            <b-row>
              <b-col md="12" v-if="postObjList && postObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="postObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(post_thumbnail)="data">
                    <img v-if="data.item.post_thumbnail && data.item.post_thumbnail !== 'null'" class="img-fluid img-thumbnail" :src="data.item.post_thumbnail" alt="..." style="height: 150px; width:200px; object-fit:contain; background: #f4f4f4;" />
                    <p v-else>
                      No Image
                    </p>
                  </template>
                  <template v-slot:cell(created_on)="data">
                    {{getFormattedDateTime(data.item.created_on)}}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="postEdit('MODAL', data.item)" v-if="!data.item.editable">
                      <i class="ri-ball-pen-fill m-0"></i>
                    </b-button>
                    <b-button variant=" iq-bg-danger" @click="showPostDeleteDialog(data.item)" size="sm">
                      <i class="ri-delete-bin-line m-0"></i>
                    </b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelPostAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl">
      <PostAdd :propOpenedInModal="true" @emitClosePostAddModal="closePostAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="postAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closePostAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModelPostEdit"
      scrollable
      :title="cvEditModalHeader"
      size="xl">
      <PostEdit :propOpenedInModal="true" :propPostObj="postEditObj" @emitClosePostEditModal="closePostEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="postEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closePostEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModelPostDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>
        {{cvDelMessage}}
      </p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModelPostDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="postDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>
<script>
import { Posts } from "../../../FackApi/api/post.js"
import PostAdd from "./PostAdd"
import PostEdit from "./PostEdit"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "PostList",
  components: {
    PostAdd,
    PostEdit
  },
  data () {
    return {
      cvCardTitle: "Posts",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Post",
      cvAddModalHeader: "Add Post",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Post List Response",
      showModelPostAdd: false,
      showModelPostEdit: false,
      showModelPostDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },

        { label: "Title", key: "post_title", class: "text-left", sortable: true },
        { label: "Username", key: "user_name", class: "text-left", sortable: true },
        { label: "Description", key: "post_desc", class: "text-left", sortable: true },
        { label: "Image", key: "post_thumbnail", class: "text-left", sortable: true },
        { label: "Post Type", key: "post_type", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      postObjList: null,
      postEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      delObj: null
    }
  },
  mounted () {
    this.postList()
  },
  methods: {
    /**
     * getFormattedDateTime
     */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.error("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * postList
     */
    async postList () {
      try {
        let postListResp = await Posts.postListConsole(this)
        if (postListResp.resp_status) {
          this.postObjList = Object.values(postListResp.resp_data.data)
          this.totalRows = postListResp.resp_data_count
        }
        else {
          this.toastMsg = postListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at postList() and Exception:", err.message)
      }
    },
    /**
     * postAdd
     */
    postAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/post_add")
        }
        else {
          this.showModelPostAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at postAdd() and Exception:", err.message)
      }
    },
    /**
     * postEdit
     */
    postEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/post_edit/" + this.postEditObj.post_id)
        }
        else {
          this.postEditObj = item
          this.showModelPostEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at postEdit() and Exception:", err.message)
      }
    },
    /**
     * showPostDeleteDialog
     */
    showPostDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelPostDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showPostDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * postDelete
     */
    async postDelete () {
      try {
        let postDelResp = await Posts.postDelete(this, this.delObj.post_id)

        await ApiResponse.responseMessageDisplay(this, postDelResp)
        this.showModelPostDelete = false

        if (!postDelResp.resp_status) {
          return false
        }

        let index = this.postObjList.indexOf(this.delObj)
        this.postObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
      }
      catch (err) {
        console.error("Exception occurred at postDelete() and Exception:", err.message)
      }
    },
    /**
     * closePostAddModal
     */
    closePostAddModal (postAddData) {
      try {
        if (postAddData) {
          if (this.postObjList && this.postObjList.length >= 1) {
            let postObjLength = this.postObjList.length
            let lastId = this.postObjList[postObjLength - 1]["id"]
            postAddData.id = lastId + 1
          }
          else {
            this.postObjList = []
            postAddData.id = 11111
          }
          // postAddData.post_id = `POST${postAddData.id}`
          postAddData.created_on = moment()
          this.postObjList.unshift(postAddData)
          this.totalRows = this.totalRows + 1
        }
        this.showModelPostAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closepostAddModal() and Exception:", err.message)
      }
    },
    /**
     * closePostEditModal
     */
    closePostEditModal () {
      try {
        this.showModelPostEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closePostEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
